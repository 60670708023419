import React, {
  useRef,
  useState,
  useCallback,
  useLayoutEffect,
} from 'react';
import { useParams } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import JulotaForm from 'components/Forms/JulotaForm';
import SidePanel from 'components/Table/SidePanel';
import { Button, Tab, Tabs } from 'react-bootstrap';
import DropDown from 'components/Forms/DropDown';
import GenericUtils from 'utils/GenericUtils';
import AdvancedConfigurationService from '../services/AdvancedConfigurationService';
import 'components/Forms/styles/Forms.scss';

export default function AdvancedConfiguration() {
  const gridRef = useRef();
  const { clientId } = useParams();

  const [form, setForm] = useState();
  const [formDirty, setFormDirty] = useState(false);
  const [loading, setLoading] = useState(true);
  const [ipConfiguration, setIPConfiguration] = useState();
  const [rules, setRules] = useState([]);
  const [displayedRules, setDisplayedRules] = useState([]);

  const [columnDefs] = useState([
    {
      field: 'rule_type',
      headerName: 'Type',
      rowDrag: true,
      cellRenderer: (params) => (params.value?.toUpperCase()),
      tooltipField: 'rule_type',
    },
    {
      field: 'rule',
      headerName: 'Rule',
      tooltipField: 'rule',
    },
    {
      field: 'rule_action',
      headerName: 'Action',
      tooltipField: 'rule_action',
    },
    {
      field: 'status',
      headerName: 'Status',
      cellRenderer: (params) => (params.value === 'active' ? 'Active' : 'Inactive'),
      cellStyle: (params) => ({ ...GenericUtils.statusCellStyle(params), textAlign: 'left' }),
      tooltipField: 'status',
    },
    {
      field: 'notes',
      headerName: 'Notes',
      tooltipField: 'notes',
    },
  ]);

  const ruleFormQuestions = [
    {
      label: 'Status',
      field: 'status',
      type: 'DROPDOWN',
      required: true,
      options: [
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },
      ],
    },
    {
      label: 'Action',
      field: 'rule_action',
      type: 'DROPDOWN',
      required: true,
      options: [
        { label: 'Allow', value: 'allow' },
        { label: 'Deny', value: 'deny' },
      ],
    },
    {
      label: 'Type',
      field: 'rule_type',
      type: 'DROPDOWN',
      options: [
        { label: 'IP', value: 'ip' },
      ],
    },
    {
      label: 'Rule',
      field: 'rule',
      type: 'TEXT',
      validation: 'CIDR',
      required: true,
      placeholder: 'Enter IP or CIDR (e.g. 192.168.1.1/24)',
    },
    {
      label: 'Notes',
      field: 'notes',
      type: 'TEXT',
      required: true,
    },
  ];

  const validateAndInitialize = () => {
    const onConfirm = async () => {
      const selectedRows = gridRef.current.api.getSelectedRows();
      setForm({
        data: {
          rule_type: selectedRows[0].rule_type,
          rule: selectedRows[0].rule,
          rule_action: selectedRows[0].rule_action,
          notes: selectedRows[0].notes,
          status: selectedRows[0].status,
          isNewRule: false,
        },
        id: selectedRows[0].id,
        key: selectedRows[0].key,
        name: selectedRows[0].name,
        header: 'Edit Rule',
        questions: ruleFormQuestions,
      });
      setFormDirty(false);
    };

    onConfirm();
  };

  const onRowClicked = useCallback(() => {
    setLoading(true);
    validateAndInitialize(0);
    setLoading(false);
  }, [formDirty]);

  const onGridReady = (pars) => {
    pars.api.sizeColumnsToFit();

    window.onresize = () => {
      pars.api.sizeColumnsToFit();
    };
  };

  const fetchGridData = async () => {
    const networkRules = await AdvancedConfigurationService.getRules(clientId);

    const defaultRule = networkRules.find((r) => r.rule === '0.0.0.0/0');
    setIPConfiguration(defaultRule.rule_action === 'deny');

    setRules(networkRules);
    setDisplayedRules(networkRules.filter((r) => r.rule !== '0.0.0.0/0'));
  };

  const handleCreateRule = () => {
    setForm({
      questions: ruleFormQuestions,
      submitText: 'Create Rule',
      header: 'Create Rule',
      data: {
        rule_type: 'ip',
        rule_action: 'allow',
        status: 'active',
        isNewRule: true,
      },
    });
    setLoading(false);
  };

  const handleIPConfigurationChange = async (e) => {
    const newRules = rules.map((r) => {
      let ruleAction = r.rule_action;

      if (r.rule === '0.0.0.0/0') {
        ruleAction = e.target.value ? 'deny' : 'allow';
      }

      return {
        ...r,
        rule_action: ruleAction,
      };
    });

    await setRules(newRules);

    setIPConfiguration(e.target.value);
    AdvancedConfigurationService.pathClientRules(clientId, newRules);
  };

  const onRowDragEnd = useCallback((event) => {
    const updatedRules = [];
    event.api.forEachNode((node) => updatedRules.push(node.data));

    const defaultRule = rules.find((r) => r.rule === '0.0.0.0/0');
    updatedRules.push(defaultRule);

    AdvancedConfigurationService.pathClientRules(clientId, updatedRules);
  }, [displayedRules, setDisplayedRules]);

  useLayoutEffect(() => {
    fetchGridData();
  }, []);

  const handleCallback = async (message) => {
    if (message.type === 'FORM_DIRTY') {
      setFormDirty(message.isDirty);
    }

    if (message.type === 'FORM_SUBMITTED') {
      if (message.data.isNewRule) {
        await AdvancedConfigurationService.createRule(
          clientId,
          message.data,
        );
      } else {
        await AdvancedConfigurationService.updateRule(
          clientId,
          message.id,
          message.data,
        );
      }

      await fetchGridData();
      // Close the form after handling the submission
      setForm(null);
      setFormDirty(false);
    }
  };

  return (
    <div className="container-padding-40">
      <h2>Advanced Configuration</h2>
      <div
        className="tabs-container"
        style={{
          marginTop: 40,
        }}
      >
        <Tabs>
          <Tab
            title="Access Point Rules"
            eventKey="access_point_rules"
          >
            <div
              className="form-container row"
              style={{
                marginTop: 20,
              }}
            >
              <div className="col-6">
                <DropDown
                  question={{
                    label: 'IP Configuration',
                    options: [
                      { value: false, label: 'Open to all IPs' },
                      { value: true, label: 'IP Whitelisting' },
                    ],
                  }}
                  value={ipConfiguration}
                  callbackFunction={handleIPConfigurationChange}
                />
              </div>

              <div className={`col-12 ${ipConfiguration ? 'enabled' : 'conditionally-disabled'}`}>
                <p style={{ fontWeight: 100 }}>
                  The following rules listed in the grid below explain the access point to the
                  system. The rules are executed in the order they are displayed in this grid.
                  Once a rule is satisfied the subsequent rules will no longer be executed. You
                  can sort this rules by re-arranging the rows with the drag-and-drop capabilities
                </p>
                <div className="float-right">
                  <Button variant="link" size="sm" onClick={handleCreateRule}>
                    Create Rule
                  </Button>
                </div>
                <div
                  className="clear-both"
                  style={{ minHeight: 200 }}
                >
                  <AgGridReact
                    ref={gridRef}
                    className="ag-theme-alpine ag-container p-0"
                    rowData={displayedRules}
                    columnDefs={columnDefs}
                    rowDragManaged
                    domLayout="autoHeight"
                    suppressMoveWhenRowDragging
                    defaultColDef={{
                      sortable: false,
                      filter: true,
                      suppressMovable: true,
                      suppressMenu: true,
                      resizable: true,
                    }}
                    rowSelection="single"
                    suppressContextMenu="true"
                    suppressCellFocus="true"
                    suppressScrollOnNewData="true"
                    onRowClicked={onRowClicked}
                    onGridReady={onGridReady}
                    onRowDragEnd={onRowDragEnd}
                  />
                </div>
              </div>
            </div>
          </Tab>
        </Tabs>
        {
          form
          && (
            <SidePanel
              close={() => {
                gridRef.current.api.deselectAll();
                setForm(null);
              }}
              confirmClosePrompt={formDirty}
              style={{ width: '600px' }}
              loading={loading}
            >
              <SidePanel.Header
                title={form.header}
              />
              <SidePanel.Content className="no-tabs">
                <JulotaForm
                  inputData={form}
                  callbackFunction={(message) => handleCallback(message)}
                  formDirty={formDirty}
                />
              </SidePanel.Content>
            </SidePanel>
          )
        }
      </div>
    </div>
  );
}
