import React from 'react';
import { Button } from 'react-bootstrap';

export default function ConfirmToast(props) {
  const {
    config: {
      title, message, confirmButton, cancelButton,
    },
    confirm = () => {},
    cancel = () => {},
    closeToast = () => {},
    children,
  } = props;

  const onCancel = () => {
    closeToast();
    cancel();
  };

  const onConfirm = () => {
    closeToast();
    confirm();
  };

  return (
    <div className="confirm-toast">
      {title && <h5>{title}</h5>}
      <div className="toast-message">
        {message}
        {children}
      </div>
      <div className="toast-buttons">
        {confirmButton && <Button variant="primary" onClick={onConfirm}>{confirmButton}</Button>}
        {cancelButton && <Button variant="outline-secondary" onClick={onCancel}>{cancelButton}</Button>}
      </div>
    </div>
  );
}
