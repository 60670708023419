import React from 'react';
import GenericUtils from 'utils/GenericUtils';
import { toast } from 'react-toastify';
import ConfirmToast from 'modules/core/components/ConfirmToast';
import { initialTabs } from '../const/const';
import {
  onGenerateUserName,
  onRemoveTeam,
  onAddTean,
  onAddOrganization,
  onRemoveOrganization,
  onMarkPrimaryOrganization,
} from '../utils/utils';
import UsersService from '../services/UsersService';

export default function useFormSubmit({
  activeTabId,
  isViewModeTogglable,
  setTabs,
  create,
  clientId,
  selectedUserData,
  fetchUsers,
  setCreate,
  gridRef,
  setSelectedRow,
  setActiveTabId,
  setFormDirty,
  setLoading,
  setSelectedUserData,
  tabs,
}) {
  const onUserSubmit = async ({ data }) => {
    const isUsernameAvailable = await UsersService.isUsernameAvailable(data.username);
    if (create && !isUsernameAvailable) {
      toast(
        <ConfirmToast config={GenericUtils.usernameAlreadyExistsToastConfig} />,
        GenericUtils.confirmToastOptions,
      );

      return null;
    }

    const user = await UsersService.createOrUpdateUser(create, {
      user_id: data.user_id,
      client_id: clientId,
      data: create ? data : GenericUtils.getObjectsDifference(selectedUserData, data),
    });

    if (create) {
      await fetchUsers(); // Must fetchUsers here in order to be able to select user from table
      setCreate(false);
      setTabs(initialTabs);
      gridRef.current.api.forEachNode((node) => {
        if (node.data.id === user.id) {
          node.setSelected(true, true);
          setSelectedRow(node.data);
          setActiveTabId(0);
        }
      });
      toast.success('User Created.');
    } else {
      toast.success('User Updated.');
    }

    return user.id;
  };

  const onRolesSubmit = async ({ data, userId }) => {
    await UsersService.updateUserRoles(userId, data.roles);
    toast.success('User Access Roles Updated.');
  };

  const onAuthSubmit = async ({ data }) => {
    await UsersService.createOrUpdateUser(false, {
      user_id: data.user_id,
      client_id: clientId,
      data: {
        mfa_sms: data.mfa_sms,
        mfa_email: data.mfa_email,
      },
    });

    toast.success('User Authentication Updated.');
  };

  const onTeamsSubmit = async ({ data }) => {
    const oldTeams = await UsersService.getUserTeams(selectedUserData.user_id, clientId);
    const newTeams = data.teams;
    const teamsAdded = [];
    const teamsRemoved = [];

    // figure out what teams were added and removed
    newTeams.forEach((newTeam) => {
      const found = oldTeams.find((oldTeam) => oldTeam.id === newTeam.id);
      if (!found) {
        newTeam.user_id = selectedUserData.user_id;
        teamsAdded.push(newTeam);
      }
    });

    oldTeams.forEach((oldTeam) => {
      const found = newTeams.find((newTeam) => newTeam.id === oldTeam.id);
      if (!found) {
        oldTeam.user_id = selectedUserData.user_id;
        teamsRemoved.push(oldTeam);
      }
    });

    await UsersService.updateUsersTeams(selectedUserData.user_id, teamsAdded, teamsRemoved);
    toast.success('User Teams Updated.');
  };

  const onOrganizationsSubmit = async (content) => {
    const oldOrgs = await UsersService.getUserOrgs(selectedUserData.user_id);
    const newOrgs = content.organizations;
    const orgsUpdated = [];
    const orgsRemoved = [];

    newOrgs.forEach((newOrg) => {
      const found = oldOrgs.find((oldOrg) => oldOrg.id === newOrg.id);
      if (!found) {
        orgsUpdated.push(newOrg); // Orgs that were added
      }
    });

    oldOrgs.forEach((oldOrg) => {
      const found = newOrgs.find((o) => o.id === oldOrg.id);

      if (!found) {
        orgsRemoved.push(oldOrg.id); // Orgs that were removed
      } else if (JSON.stringify(found) !== JSON.stringify(oldOrg)) {
        orgsUpdated.push(found); // Orgs that were changed
      }
    });

    await UsersService.updateUserOrgs(selectedUserData.user_id, orgsUpdated, orgsRemoved);
    toast.success('User Organizations Updated.');
  };

  const onHubsSubmit = async (content) => {
    toast.error('Not Implemented.');
  };

  const getUserById = async ({ userId }) => {
    const resp = await UsersService.getUserById(userId);
    setSelectedUserData(resp);

    return resp;
  };

  const fetchUserDetailDataByTabName = async ({
    tabName, clientID, userData,
  }) => {
    setLoading(true);

    const tabData = await UsersService.getTabDataByTabName(
      userData ?? selectedUserData,
      tabName,
      clientID,
      tabs,
    );

    setTabs((prev) => prev.map((item) => {
      if (item.title === tabData.title) {
        return {
          title: item.title,
          form: { ...tabData.form },
        };
      }

      return item;
    }));

    setLoading(false);
  };

  const onFormSubmit = async ({ id, data }) => {
    try {
      setLoading(true);

      switch (activeTabId) {
        case 0: {
          const userId = await onUserSubmit({ data });
          const userData = await getUserById({ userId: userId || id });
          await fetchUserDetailDataByTabName({
            tabName: 'User Detail',
            userData,
          });
          await fetchUserDetailDataByTabName({
            tabName: 'Authentication',
            userData,
          });
          break;
        }
        case 1:
          await onRolesSubmit({ data, userId: id });
          break;
        case 2: {
          await onAuthSubmit({ data });
          const userData = await getUserById({ userId: id });
          await fetchUserDetailDataByTabName({
            tabName: 'User Detail',
            userData,
          });
          break;
        }
        case 3:
          await onTeamsSubmit({ data });
          break;
        case 5:
          await onOrganizationsSubmit(data);
          break;
        case 6:
          await onHubsSubmit(data);
          break;
        default:
          break;
      }

      if (isViewModeTogglable) {
        setTabs(((prev) => prev.map((item) => {
          if (item.title === initialTabs[activeTabId].title) {
            return {
              ...item,
              form: {
                ...item.form,
                viewMode: true,
              },
            };
          }

          return item;
        })
        ));
      }

      setFormDirty(false);
      await fetchUsers();
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCallback = (message) => {
    if (message.type === 'GENERATE_USERNAME') {
      return onGenerateUserName({ message });
    }

    if (message.type === 'ADD_TEAM') {
      return onAddTean({ message });
    }

    if (message.type === 'REMOVE_TEAM') {
      return onRemoveTeam({ message, username: selectedUserData.username });
    }

    if (message.type === 'ADD_ORGANIZATION') {
      return onAddOrganization({ message });
    }

    if (message.type === 'REMOVE_ORGANIZATION') {
      return onRemoveOrganization({ message, username: selectedUserData.username });
    }

    if (message.type === 'MARK_PRIMARY_ORGANIZATION') {
      return onMarkPrimaryOrganization({ message });
    }

    if (message.type === 'FORM_DIRTY') {
      setFormDirty(message.isDirty);
      if (create && message.name === 'first_name') {
        return { nickname: message.value };
      }
    } else if (message.type === 'FORM_SUBMITTED') {
      const { id, data } = message;
      onFormSubmit({ id, data });
    }

    return null;
  };

  return {
    handleCallback,
    fetchUserDetailDataByTabName,
    getUserById,
  };
}
