export const tabList = [
  'User Detail',
  'Access Roles',
  'Authentication',
  'Teams',
  'Notifications',
  'Organizations',
  'Hubs',
];

export const initialTabs = new Array(tabList.length).fill(null).map((item, index) => ({
  title: tabList[index],
  form: null,
}));
