/* eslint-disable object-curly-newline */
import { React } from 'react';
import hash from 'object-hash';
import {
  Plus,
  PencilSquare,
  EyeFill,
  Trash,
  ArrowUp,
  ArrowDown,
  FilePlus,
  Link45deg,
  List,
} from 'react-bootstrap-icons';
import { Button, Card, Dropdown } from 'react-bootstrap';

import ComponentRow from './ComponentRow';

export default function ComponentPanel(props) {
  const {
    component,
    cIndex,
    editMode,
    level,
    showMoveUp,
    showMoveDown,
    callbackFunction,
    swap,
    titleOverwrite,
  } = props;

  const handleCallback = (action, container) => {
    callbackFunction({
      action,
      component,
      container,
      index: cIndex,
    });
  };

  const getRowActionLinks = () => {
    const rowActions = [
      { key: 'create-draft-link', title: 'Create Draft', icon: <FilePlus />, action: 'UPSERT_DRAFT', showCondition: level === 'component' && component?.status !== 'draft' && component?.status === 'current' },
      { key: 'view-component-link', title: 'View', icon: <EyeFill />, action: 'VIEW_COMPONENT', showCondition: level === 'component' && component?.status !== 'draft' },
      { key: 'edit-component-link', title: 'Edit', icon: <PencilSquare />, action: 'EDIT_COMPONENT', showCondition: editMode && level === 'component' && component?.status === 'draft' },
      { key: 'edit-layout-link', title: 'Edit', icon: <PencilSquare />, action: 'EDIT_LAYOUT_COMPONENT', showCondition: level === 'layout' && editMode },
      { key: 'discard-changes-link', title: 'Discard Draft', icon: <Trash />, action: 'DISCARD_CHANGES', showCondition: editMode && level === 'component' && component?.status === 'draft' },
      { key: 'delete-layout-link', title: 'Delete', icon: <Trash />, action: 'DELETE_LAYOUT_COMPONENT', showCondition: level === 'layout' && editMode && !component.protected },
      { key: 'move-up-component-link', title: 'Move Up', icon: <ArrowUp />, action: 'MOVE_UP_LAYOUT_COMPONENT', showCondition: level === 'layout' && editMode && showMoveUp },
      { key: 'move-down-component-link', title: 'Move Down', icon: <ArrowDown />, action: 'MOVE_DOWN_LAYOUT_COMPONENT', showCondition: level === 'layout' && editMode && showMoveDown },
      { key: 'layout-details-link', title: 'Details', icon: <EyeFill />, action: 'DETAILS_LAYOUT_COMPONENT', showCondition: level === 'layout' && !editMode },
      { key: 'edit-layout-component-link', title: 'Component', icon: <Link45deg />, action: 'EDIT_COMPONENT', showCondition: level === 'layout' },
    ];

    return rowActions.filter((a) => a.showCondition);
  };

  return (
    <Card key={component.layout.uid} style={{ marginRight: 0 }}>
      <Card.Body>
        <Card.Title>
          <div className="title-container">
            <div className="float-right text-align-right action-links">
              {/* {
                getRowActionLinks().map((link) => (
                  <Button variant="link" size="sm" onClick={() => handleCallback(link.action)}>
                    {link.icon}
                    <span>{ link.title }</span>
                  </Button>
                ))
              } */}
              <Dropdown>
                <Dropdown.Toggle variant="link" id="dropdown-basic" style={{ padding: 0 }}>
                  <List />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {
                    getRowActionLinks().map((link) => (
                      <Dropdown.Item key={link.key} variant="link" size="sm" onClick={() => handleCallback(link.action)}>
                        {link.icon}
                        <span style={{ paddingLeft: 5 }}>{link.title}</span>
                      </Dropdown.Item>
                    ))
                  }
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="component-title">
              {titleOverwrite || component.layout.title}
            </div>
          </div>
        </Card.Title>
        <div className="box row">
          {component.layout.rows.map((item, index) => (
            <ComponentRow
              key={hash({ item })}
              editMode={editMode}
              level={level}
              item={item}
              index={index}
              swap={swap}
              callbackFunction={handleCallback}
            />
          ))}
          {editMode && level === 'component' && (
            <div className="col-md-12">
              <Button variant="link" size="sm" onClick={() => handleCallback('ADD_CONTAINER')} className="add-element">
                <Plus />
                Add Container
              </Button>
              <Button variant="link" size="sm" onClick={() => handleCallback('ADD_DATASET_ELEMENT')} className="add-element">
                <Plus />
                Create Dataset Element
              </Button>
            </div>
          )}
        </div>
      </Card.Body>
    </Card>
  );
}
