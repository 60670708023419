/* eslint-disable object-curly-newline */
import React from 'react';
import hash from 'object-hash';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';
import { Button, Dropdown } from 'react-bootstrap';
import {
  EyeFill,
  PencilSquare,
  Trash,
  Eye,
  EyeSlash,
  PlusCircleFill,
  List,
} from 'react-bootstrap-icons';
import ReachFormControl from 'components/Reach/ReachFormControl';
import DropDown from 'components/Reach/DropDown';
import ReachButtonGroup from 'components/Reach/ReachButtonGroup';
import DatePicker from 'components/Reach/DatePicker';
import TimePicker from 'components/Reach/TimePicker';
import DateTimePicker from 'components/Reach/DateTimePicker';
import ReachFormCheck from 'components/Reach/ReachFormCheck';
import FileUpload from 'components/Reach/FileUpload';

export default function ComponentRow(props) {
  const {
    item,
    editMode,
    level,
    index,
    swap,
    callbackFunction,
  } = props;

  const handleDrop = (e) => {
    e.stopPropagation();
    swap(e.dragData.index, index, e.dragData.item);
  };

  const renderElement = (element) => {
    switch (element.display_as) {
      case 'text': return <ReachFormControl key={hash({ element })} question={element} as="input" />;
      case 'multiline-text': return <ReachFormControl key={hash({ element })} question={element} as="textarea" />;
      case 'dropdown': return <DropDown key={hash({ element })} question={element} />;
      case 'button-group': return <ReachButtonGroup key={hash({ element })} question={element} />;
      case 'radio-group': return <ReachFormCheck key={hash({ element })} question={element} type="radio" />;
      case 'check-group': return <ReachFormCheck key={hash({ element })} question={element} type="check" />;
      case 'date': return <DatePicker key={hash({ element })} />;
      case 'time': return <TimePicker key={hash({ element })} />;
      case 'elapsed-time': return <DateTimePicker key={hash({ element })} />;
      case 'file-upload': return <FileUpload key={hash({ element })} />;
      default: return <div>NOT YET IMPLEMENTED</div>;
    }
  };

  const getLabelClass = (layout) => {
    switch (layout) {
      case '25-75': return 'col-md-3';
      case '50-50': return 'col-md-6';
      case 'above': return 'col-md-12';
      default: return null;
    }
  };

  const getQuestionClass = (layout) => {
    switch (layout) {
      case '50-50': return 'col-md-6 reach-question';
      case '25-75': return 'col-md-9 reach-question';
      case 'above': return 'col-md-12 reach-question-with-padding';
      default: return 'col-md-12';
    }
  };

  const getRowClass = (i) => {
    switch (i.row_layout) {
      case 'full': return 'col-md-12';
      case 'half-left': return 'col-md-6 mr-6';
      case 'half-right': return 'col-md-6 offset-md-6';
      case 'half-floated': return 'col-md-6';
      default: return 'col-md-12';
    }
  };

  const getRowActionLinks = () => {
    const rowActions = [
      { title: 'Edit', icon: <PencilSquare />, action: 'EDIT_CONTAINER', showCondition: level === 'component' && editMode },
      { title: 'Delete', icon: <Trash />, action: 'DELETE_CONTAINER', showCondition: level === 'component' && editMode && !item.protected },
      { title: 'View', icon: <EyeFill />, action: 'VIEW_CONTAINER', showCondition: level === 'component' && !editMode },
      { title: 'Hide', icon: <EyeSlash />, action: 'HIDE_CONTAINER', showCondition: level === 'layout' && editMode && item.status === 'SHOWING' },
      { title: 'Show', icon: <Eye />, action: 'SHOW_CONTAINER', showCondition: level === 'layout' && editMode && item.status === 'HIDDEN' },
    ];

    return rowActions.filter((a) => a.showCondition);
  };

  const duplicateHtmlElement = (id) => {
    const node = document.getElementById(id);
    const clone = node.cloneNode(true);
    document.getElementById(id).after(clone);
  };

  return (
    <div className={`box_item_component ${getRowClass(item)}`}>
      <DragDropContainer
        targetKey="drag-drop-key"
        dragData={{ item, index }}
        disappearDraggedElement
        dragHandleClassName="grabber"
        yOnly
      >
        <DropTarget onHit={handleDrop} targetKey={item.fixed_position ? 'invalid-target' : 'drag-drop-key'}>
          <div className="outer">
            <div className="item">
              <div className={editMode && item.status === 'HIDDEN' ? 'row m-0 hiddenRow' : 'row m-0'} style={{ width: '100%' }}>
                <div className={`${getLabelClass(item.layout)} reach-form-label`}>
                  {item.label_overwrite || item.label}
                </div>
                <div className={`${getQuestionClass(item.layout)} pe-0`}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ flex: 1 }}>
                      <div id={`answer_${item.id}`}>
                        {item.elements?.map((element) => (
                          renderElement(element)
                        ))}
                      </div>
                      {
                        item.allow_many && (
                          <span>
                            <Button variant="link" size="sm" onClick={() => duplicateHtmlElement(`answer_${item.id}`)} style={{ padding: 0 }}>
                              <PlusCircleFill color="rgba(0, 139, 139, 0.7)" />
                            </Button>
                          </span>
                        )
                      }
                    </div>
                    <div className="float-right text-align-right action-links">
                      {/* {
                        getRowActionLinks().map((link) => (
                          <Button variant="link" size="sm" onClick={() =>
                             callbackFunction(link.action, item)}>
                            {link.icon}
                            <span>{ link.title }</span>
                          </Button>
                        ))
                      } */}
                      {editMode && level === 'component' && !item.fixed_position && (
                        <span className="grabber">&#8759;</span>
                      )}
                      {
                        getRowActionLinks().length > 0 && (
                          <Dropdown style={{ display: 'inline' }}>
                            <Dropdown.Toggle variant="link" id="dropdown-basic" style={{ padding: 0, display: 'inline' }}>
                              <List />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {
                                getRowActionLinks().map((link) => (
                                  <Dropdown.Item variant="link" size="sm" onClick={() => callbackFunction(link.action, item)}>
                                    {link.icon}
                                    <span style={{ paddingLeft: 5 }}>{link.title}</span>
                                  </Dropdown.Item>
                                ))
                              }
                            </Dropdown.Menu>
                          </Dropdown>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DropTarget>
      </DragDropContainer>
    </div>
  );
}
