import { React } from 'react';
import {
  Link,
  LinkProps,
  useMatch,
  useResolvedPath,
} from 'react-router-dom';

export default function CustomLink({ children, to, ...props }) {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: false });

  return (
    <Link className={match ? 'active' : 'none'} to={to} {...props}>
      {children}
    </Link>
  );
}
