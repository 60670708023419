import FetchService from 'services/FetchService';

export default class InterfacesService {
  static getClientInterfaces(clientId) {
    return FetchService.fetchData('GET', `interfaces?client_id=${clientId}`);
  }

  static getClientInterfaceHistory(clientId, interfaceId, type) {
    return FetchService.fetchData('GET', `interfaces/${interfaceId}/history?client_id=${clientId}&type=${type}`);
  }

  static getClientInterfaceInfo(clientId, interfaceId, type) {
    return FetchService.fetchData('GET', `interfaces/${interfaceId}?client_id=${clientId}`, { interfaceId, type });
  }

  static updateClientInterface(interfaceId, body) {
    return FetchService.fetchData('PATCH', `interfaces?interface_id=${interfaceId}`, body);
  }
}
