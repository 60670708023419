/* eslint-disable arrow-body-style */
import '../styles/Profile.scss';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CustomCard from 'components/CustomCard';
import ClientDashboardService from 'services/ClientDashboardService';

export default function Info() {
  const { clientId } = useParams();
  const [dashboardData, setDashboardData] = useState();

  const fetchDashboard = async () => {
    const dashboard = await ClientDashboardService.getClientDashboard(clientId);
    setDashboardData(Object.values(dashboard));
  };

  useEffect(() => {
    fetchDashboard();
  }, []);

  const getCardContent = (obj) => {
    return Object.entries(obj).map(([key, value]) => (
      <div className="space-between" key={key}>
        <p>{key}</p>
        <p>{value}</p>
      </div>
    ));
  };

  const getDashboardCard = (data) => {
    return Array.isArray(data) ? data.map(getCardContent) : getCardContent(data);
  };

  return (
    <div className="profile container-padding-40">
      <h2>Dashboard</h2>
      <hr />

      <div className="row" style={{ flexFlow: 'wrap' }}>
        {dashboardData && dashboardData.map((d) => (
          <CustomCard
            title={d.label}
            description={d.description || ''}
            key={d.label}
          >
            {getDashboardCard(d.data)}
          </CustomCard>
        ))}
      </div>
    </div>
  );
}
