import { AgGridReact } from 'ag-grid-react';
import JulotaForm from 'components/Forms/JulotaForm';
import SearchBar from 'components/Search/SearchBar';
import DescriptionCellRenderer from 'components/Table/DescriptionCellRenderer';
import SidePanel from 'components/Table/SidePanel';
import Subheader from 'components/Subheader';
import cronstrue from 'cronstrue';
import {
  React,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Tab,
  Tabs,
} from 'react-bootstrap';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import RunHistory from 'modules/core/components/RunHistory';
import GenericUtils from 'utils/GenericUtils';
import ConfirmToast from 'modules/core/components/ConfirmToast';
import Tooltip from 'components/Table/Tooltip';
import InterfacesService from '../services/InterfacesService';

export default function Interfaces() {
  const { clientId } = useParams();
  const [activeTabKey, setActiveTabKey] = useState('info');
  const [interfaces, setInterfaces] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const initialForm = useRef();
  const [form, setForm] = useState();
  const [selectedRow, setSelectedRow] = useState({});
  const [formDirty, setFormDirty] = useState(false);
  const [loading, setLoading] = useState(true);
  const [columnDefs] = useState([
    {
      headerName: 'Strategy / Type',
      // eslint-disable-next-line react/no-unstable-nested-components
      cellRenderer: ({ data }) => (
        <DescriptionCellRenderer name={data.strategy} description={data.type} />
      ),
      sortable: true,
      tooltipValueGetter: (p) => ({
        title: p.data.strategy,
        subtitle: p.data.type,
      }),
      tooltipComponent: Tooltip,
    },
    {
      field: 'location',
      tooltipField: 'location',
      sortable: true,
    },
    {
      field: 'interval',
      tooltipField: 'interval',
      headerClass: 'text-center',
      cellClass: 'text-center',
      cellRenderer: ({ data }) => {
        if (data.cron_interval) {
          return cronstrue.toString(data.cron_interval);
        }

        if (data.interval) {
          return GenericUtils.formatIntervalIntoHumanReadable(data.interval);
        }

        return '-';
      },
      sortable: true,
      width: 75,
    },
    {
      field: 'status',
      headerClass: 'text-center',
      tooltipValueGetter: (p) => (p.data.status ? 'Active' : 'Inactive'),
      cellRenderer: (cellParams) => (cellParams.value ? 'Active' : 'Inactive'),
      cellStyle: (params) => GenericUtils.statusCellStyle(params),
      width: 75,
    },
  ]);

  const handleSelectTab = (newKey) => {
    const onConfirm = () => {
      setForm(structuredClone(initialForm.current));
      setFormDirty(false);
      setActiveTabKey(newKey);
    };

    if (formDirty) {
      toast(
        <ConfirmToast
          config={GenericUtils.discardChangesToastConfig}
          confirm={onConfirm}
        />,
        GenericUtils.confirmToastOptions,
      );
    } else {
      setActiveTabKey(newKey);
    }
  };

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();

    window.onresize = () => {
      params.api.sizeColumnsToFit();
    };
  };

  const fetchGridData = async () => {
    const res = await InterfacesService.getClientInterfaces(clientId);
    setInterfaces(res);
    setGridData(res);
  };

  const onRowClicked = useCallback(async (params) => {
    const onConfirm = async () => {
      const {
        id: interfaceId,
        strategy,
        type,
      } = params.api.getSelectedRows()[0];

      const { info } = await InterfacesService.getClientInterfaceInfo(
        clientId,
        interfaceId,
        type,
      );

      setSelectedRow({ strategy, type, interfaceId });
      setForm(info);
      initialForm.current = structuredClone(info);
      setLoading(true);
      setFormDirty(false);
      setLoading(false);
    };

    if (formDirty) {
      toast(
        <ConfirmToast
          config={GenericUtils.discardChangesToastConfig}
          confirm={onConfirm}
        />,
        GenericUtils.confirmToastOptions,
      );
    } else {
      onConfirm();
    }
  }, [formDirty]);

  const closeSidePanel = () => {
    const onConfirm = () => {
      setForm(null);
      setFormDirty(false);
      setActiveTabKey('info');
    };

    if (formDirty) {
      toast(
        <ConfirmToast
          config={GenericUtils.discardChangesToastConfig}
          confirm={onConfirm}
        />,
        GenericUtils.confirmToastOptions,
      );
    } else {
      onConfirm();
    }
  };

  const handleCallback = async (message) => {
    if (message.type === 'FORM_DIRTY') {
      setFormDirty(message.isDirty);
    } else if (message.type === 'FORM_SUBMITTED') {
      const { data } = message;

      setLoading(true);

      try {
        await InterfacesService.updateClientInterface(data.id, data);

        toast.success('Changes Saved!');
        setFormDirty(false);
        fetchGridData();
      } catch (error) {
        toast.error(error);
      }

      setLoading(false);
    }
  };

  const fetchClientInterfaceHistory = async (interfaceId, type) => {
    const { history } = await InterfacesService.getClientInterfaceHistory(
      clientId,
      interfaceId,
      type,
    );
    setSelectedRow((prev) => ({ ...prev, history }));
  };

  useEffect(() => {
    fetchGridData();
  }, []);

  useEffect(() => {
    if (searchText.length > 0) {
      setGridData(GenericUtils.search(interfaces, searchText));
    } else {
      setGridData(interfaces);
    }
  }, [searchText]);

  useEffect(() => {
    if (activeTabKey === 'history' && !selectedRow.history) {
      fetchClientInterfaceHistory(selectedRow.interfaceId, selectedRow.type);
    }
  }, [activeTabKey]);

  return (
    <div className="container-padding-40">
      <div className="row">
        <div className="col-lg-3 col-6">
          <h2>Interfaces</h2>
          <Subheader description={[`${gridData ? gridData.length.toLocaleString() : 0} items`]} />
        </div>
        <div className="col-md-6">
          <SearchBar
            placeholder="Search Interfaces..."
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
      </div>

      <div className="ag-theme-alpine ag-container">
        <AgGridReact
          rowData={gridData}
          columnDefs={columnDefs}
          defaultColDef={{
            sortable: true,
            filter: true,
            suppressMovable: true,
            suppressMenu: true,
            resizable: true,
          }}
          tooltipShowDelay={1500}
          rowSelection="single"
          suppressContextMenu="true"
          rowHeight="65"
          suppressCellFocus="true"
          suppressScrollOnNewData="true"
          onRowClicked={onRowClicked}
          onGridReady={onGridReady}
        />
      </div>

      {
        (form && selectedRow) && (
          <SidePanel
            className="side-panel"
            close={closeSidePanel}
            confirmClosePrompt={formDirty}
            loading={loading}
            style={{ width: '60%' }}
          >
            <SidePanel.Header
              title={selectedRow.strategy}
              subtitle={selectedRow.type}
              style={{ borderBottom: 0 }}
            />

            <SidePanel.Content>
              <div className="tabs-container">
                <Tabs
                  activeKey={activeTabKey}
                  onSelect={handleSelectTab}
                  mountOnEnter
                  unmountOnExit
                >
                  <Tab
                    eventKey="info"
                    title="Info"
                    key="info"
                  >
                    <JulotaForm
                      inputData={form}
                      callbackFunction={(message) => handleCallback(message)}
                      formDirty={formDirty}
                    />
                  </Tab>
                  <Tab
                    eventKey="history"
                    title="Run History"
                    key="runHistory"
                  >
                    {selectedRow.history
                      && (
                      <RunHistory
                        type={selectedRow.type}
                        data={selectedRow.history.data}
                        summary={selectedRow.history.summary}
                      />
                      )}
                  </Tab>
                </Tabs>
              </div>
            </SidePanel.Content>
          </SidePanel>
        )
      }

    </div>
  );
}
