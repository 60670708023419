import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
  persistReducer, persistStore, FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import authReducers from './reducers/AuthCoreReducers';
import clientReducer from './reducers/ClientReducer';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedClientReducer = persistReducer(persistConfig, clientReducer);

export const store = configureStore({
  reducer: {
    coreModule: authReducers,
    selectedClient: persistedClientReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export const persistor = persistStore(store);
