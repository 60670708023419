import React from 'react';
import { Form } from 'react-bootstrap';
import UsageGroupByDate from './UsageGroupByDate';
import UsageLoading from './UsageLoading';
import UsageNoData from './UsageNoData';

function UsageDetailsData({ dataDetails, usageSetDetailsSearch, loading }) {
  const setComponent = () => {
    if (!loading && Object.keys(dataDetails).length > 0) {
      return <UsageGroupByDate data={dataDetails} />;
    }
    if (!loading && Object.keys(dataDetails).length === 0) {
      return <UsageNoData />;
    }

    return <UsageLoading />;
  };

  return (
    <>
      <div>
        <Form.Control
          type="text"
          placeholder="Search..."
          className="search-control"
          onChange={usageSetDetailsSearch}
        />
      </div>
      {setComponent()}
    </>
  );
}

export default UsageDetailsData;
