import {
  React,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useParams, useNavigate } from 'react-router';
import SearchBar from 'components/Search/SearchBar';
import Subheader from 'components/Subheader';
import GenericUtils from 'utils/GenericUtils';
import LayoutService from '../services/LayoutService';

export default function Layouts() {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const [components, setComponents] = useState('');
  const [gridData, setGridData] = useState([]);
  const [searchText, setSearchText] = useState('');

  const [columnDefs] = useState([
    {
      field: 'layout_key',
      tooltipField: 'layout_key',
      headerName: 'Key',
      sortable: true,
    },
    {
      field: 'entity_type',
      tooltipField: 'entity_type',
      headerName: 'Entity Type',
      sortable: true,
    },
    {
      field: 'entity_key',
      tooltipField: 'entity_key',
      headerName: 'Entity Key',
      sortable: true,
    },
    {
      field: 'components_counts',
      tooltipField: 'components_counts',
      headerName: 'Components',
      sortable: true,
      width: 100,
    },
    {
      field: 'component_categories',
      tooltipField: 'component_categories',
      headerName: 'Component Categories',
      sortable: true,
    },
  ]);

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();

    window.onresize = () => {
      params.api.sizeColumnsToFit();
    };
  };

  const onRowClicked = useCallback(async (params) => {
    const {
      layout_key: layoutKey,
      entity_type: entityType,
      entity_key: entityKey,
    } = params.api.getSelectedRows()[0];

    navigate(`/hubs/${clientId}/layouts/${layoutKey}`);
  }, []);

  const fetchGridData = async () => {
    const res = await LayoutService.getAll(clientId);

    setComponents(res);
    setGridData(res);
  };

  useEffect(() => {
    fetchGridData();
  }, []);

  useEffect(() => {
    if (searchText.length > 0) {
      setGridData(GenericUtils.search(components, searchText));
    } else {
      setGridData(components);
    }
  }, [searchText]);

  return (
    <div className="container-padding-40">
      <div className="row">
        <div className="col-lg-3 col-6">
          <h2>Layouts</h2>
          <Subheader description={[`${gridData ? gridData.length.toLocaleString() : 0} items`]} />
        </div>
        <div className="col-md-6">
          <SearchBar
            placeholder="Search Layouts..."
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
      </div>

      <div className="ag-theme-alpine ag-container">
        <AgGridReact
          rowData={gridData}
          columnDefs={columnDefs}
          defaultColDef={{
            sortable: true,
            filter: true,
            suppressMovable: true,
            suppressMenu: true,
            resizable: true,
          }}
          tooltipShowDelay={1500}
          rowSelection="single"
          suppressContextMenu="true"
          rowHeight="65"
          suppressCellFocus="true"
          suppressScrollOnNewData="true"
          onRowClicked={onRowClicked}
          onGridReady={onGridReady}
        />
      </div>
    </div>
  );
}
