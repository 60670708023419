import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import set from 'lodash.set';
import { useSelector } from 'react-redux';
import { selectSessionHasWriteAccess } from 'redux/selectors';
import { useBlocker } from 'hooks/useBlocker';
import { Alert, Form, Button } from 'react-bootstrap';
import GenericUtils from 'utils/GenericUtils';
import hash from 'object-hash';
import Toggle from 'components/Forms/Toggle';
import TextInput from 'components/Forms/TextInput';
import TextArea from 'components/Forms/TextArea';
import JsonInput from 'components/Forms/JsonInput';
import DropDown from 'components/Forms/DropDown';
import InputMask from 'components/Forms/InputMask';
import AddressLookup from 'components/Forms/AddressLookup';
import EditableTable from 'components/Forms/EditableTable';
import ReadOnly from 'components/Forms/ReadOnly';
import Terminology from 'components/Forms/Terminology';
import CronInput from 'components/Forms/CronInput';
import MultiSelect from 'components/Forms/MultiSelect';
import RulesObject from 'components/Forms/RulesObject';
import SidePanel from 'components/Table/SidePanel';
import 'components/Forms/styles/Forms.scss';
import { toast } from 'react-toastify';
import ConfirmToast from 'modules/core/components/ConfirmToast';
import DatePicker from './DatePicker';
import NumberPicker from './NumberPicker';
import RangeSlider from './RangeInput';
import MessageIcon from './MessageIcon';

export default function JulotaForm(props) {
  const { inputData, callbackFunction, formDirty } = props;
  const initialFormData = useRef();
  const [formData, setForm] = useState({});
  const [questions, setQuestions] = useState([]);
  const [validated, setValidated] = useState(false);
  const [panel, setPanel] = useState(null);
  const [fieldsWithErrors, setFieldsWithErrors] = useState([]);
  const hasWriteAccess = useSelector(selectSessionHasWriteAccess);

  const getDeepValue = (obj, path) => {
    const paths = path.split('.');
    let curr = { ...obj };

    // eslint-disable-next-line no-restricted-syntax
    for (const p of paths) {
      if (curr[p] === undefined) { return undefined; }
      curr = curr[p];
    }

    return curr;
  };

  const isQuestionConditionalDisabled = (question, f) => {
    const conditions = question.conditions?.map((c) => {
      switch (c.operator) {
        case 'ARRAY_SIZE_GREATER_THAN':
          return f[c.field]?.length > c.value;

        default: return f[c.field] === c.value;
      }
    }) || [];

    return conditions.includes(false);
  };

  const markFormDirty = (isDirty) => {
    callbackFunction({
      type: 'FORM_DIRTY',
      isDirty,
      data: formData,
    });
  };

  const sendMessage = (type, data, name, value) => callbackFunction({
    type,
    data,
    name,
    value,
  });

  const renderForm = (fData) => {
    setForm(fData);
    const updatedQuestions = inputData.questions.map((q) => {
      const isConditionallyDisabled = isQuestionConditionalDisabled(q, fData);

      return {
        ...q,
        isConditionallyDisabled,
        required: q.required && !isConditionallyDisabled,
      };
    });

    setQuestions(updatedQuestions);

    if (inputData.disabled) {
      markFormDirty(false);
    }
  };

  const handleMessageResult = (result) => {
    if (result) {
      const changedValues = GenericUtils.getObjectsDifference(formData, result);
      Object.keys(changedValues).forEach((key) => {
        set(formData, key, getDeepValue(changedValues, key));
      });
    }
  };

  const onButtonClick = async (type, name, value, callback) => {
    const callbackValues = await sendMessage(type, formData, name, value);
    handleMessageResult(callbackValues);
    renderForm(formData);

    const isFormDirty = !GenericUtils.areObjectsEqual(formData, initialFormData.current);
    markFormDirty(isFormDirty);

    if (typeof callback === 'function') {
      callback();
    }
  };

  const closeEditPanel = () => {
    setPanel(null);
  };

  const handleEdits = (e) => {
    if (e.type === 'ON_CHANGE') {
      setPanel({
        title: panel.title,
        subtitle: panel.subtitle,
        field: panel.field,
        rowIndex: panel.rowIndex,
        form: {
          questions: panel.form.questions,
          data: { ...e.data },
        },
      });
    }
    if (e.type === 'FORM_SUBMITTED') {
      const newTasks = formData[panel.field].slice();
      newTasks[panel.rowIndex] = e.data;

      set(formData, panel.field, newTasks);
      markFormDirty(true);
      sendMessage('ON_CHANGE', formData, panel.field, newTasks).then(handleMessageResult);
      closeEditPanel();
    }
  };

  const handleCallback = (e) => {
    const {
      name,
      value,
      fieldsToSet,
      type,
      id,
      callback,
    } = e.target;

    if (type === 'BUTTON') {
      onButtonClick(id, name, value, callback);

      return;
    }

    if (type === 'NOT_VALID') {
      setFieldsWithErrors([...fieldsWithErrors, name]);
    }
    if (type === 'VALID') {
      setFieldsWithErrors(fieldsWithErrors.filter((field) => field !== name));
    }

    if (fieldsToSet && value.length > 0) {
      fieldsToSet.forEach((field) => {
        set(formData, field, value.find((v) => v.name === field).value);
      });
    } else {
      set(formData, name, value);
    }

    sendMessage('ON_CHANGE', formData, name, value)?.then(handleMessageResult);

    renderForm(formData);

    const isFormDirty = !GenericUtils.areObjectsEqual(formData, initialFormData.current);
    markFormDirty(isFormDirty);
  };

  const onSave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;

    if (form.checkValidity() && validated) {
      callbackFunction({
        type: 'FORM_SUBMITTED',
        id: inputData.id,
        data: formData,
        initialFormData: initialFormData.current,
      });
    }

    initialFormData.current = structuredClone(formData);
    setValidated(true);
  };

  const onRowClick = async (question, params) => {
    const { data } = params;

    setPanel({
      title: data.pending_display, // TODO: This cannot be hardcoded like this
      subtitle: data.key,
      field: question.field,
      rowIndex: params.rowIndex,
      form: {
        questions: question.formQuestions,
        data: { ...params.data },
      },
    });
  };

  const renderQuestion = (question) => {
    question.disabled = inputData.disabled || question.disabled;

    let value = getDeepValue(formData, question.field);
    if ((question.maskValues) && (question.maskValues[value])) {
      value = question.maskValues[value];
    }

    const questionProps = {
      question,
      value,
      formData,
      errors: inputData.errors && getDeepValue(inputData.errors, question.field),
      formDirty,
      callbackFunction: (e) => {
        if (typeof question.onChange === 'function') {
          question.onChange(e);
        }
        handleCallback(e);
      },
      onBtnClick: (e) => {
        const message = {
          target: {
            id: e.target.id,
            type: 'BUTTON',
          },
        };
        handleCallback(message);
      },
    };

    if (inputData.viewMode && question.formatter === false) {
      return null;
    }

    if (inputData.viewMode || question.type === 'READONLY') {
      return <ReadOnly question={question} value={questionProps.value ?? '-'} />;
    }

    switch (question.type) {
      case 'TOGGLE': return <Toggle {...questionProps} />;
      case 'TEXT': return <TextInput {...questionProps} />;
      case 'TEXTAREA': return <TextArea {...questionProps} />;
      case 'JSONINPUT': return <JsonInput {...questionProps} />;
      case 'DROPDOWN': return <DropDown {...questionProps} />;
      case 'INPUTMASK': return <InputMask {...questionProps} />;
      case 'NUMBERPICKER': return <NumberPicker {...questionProps} />;
      case 'ADDRESSLOOKUP': return <AddressLookup {...questionProps} />;
      case 'EDITABLETABLE': return (
        <EditableTable {...questionProps} onRowClick={(data) => onRowClick(question, data)} />
      );
      case 'TERMINOLOGY': return <Terminology {...questionProps} />;
      case 'DATEPICKER': return <DatePicker {...questionProps} />;
      case 'CRONINPUT': return <CronInput {...questionProps} />;
      case 'MULTISELECT': return <MultiSelect {...questionProps} />;
      case 'RANGESLIDER': return <RangeSlider {...questionProps} />;
      case 'RULES': return <RulesObject {...questionProps} />;
      default: return <div>UNRECOGNIZED QUESTION TYPE</div>;
    }
  };

  const blocker = useCallback(
    (tx) => {
      if (formDirty && !inputData?.viewMode) {
        toast(
          <ConfirmToast
            config={GenericUtils.discardChangesToastConfig}
            confirm={() => {
              tx.retry(); // Unblock navigation
              markFormDirty(false);
            }}
            cancel={() => {
              markFormDirty(true);
            }}
          />,
          GenericUtils.confirmToastOptions,
        );
      }
    },
    [formDirty, inputData?.viewMode],
  );

  useBlocker(blocker, formDirty);

  useEffect(() => {
    initialFormData.current = structuredClone(inputData.data);
  }, []);

  useEffect(() => {
    setValidated(fieldsWithErrors.length === 0 && formDirty && hasWriteAccess);
    renderForm(inputData.data);
  }, [fieldsWithErrors, formDirty, inputData]);

  const getColumnSize = (question) => (
    inputData.viewMode
      ? question.viewModeColSize ?? inputData.viewModeDefaultColSize ?? 12
      : question.editModeColSize ?? inputData.editModeDefaultColSize ?? 12
  );

  const getConditionalDisabledClass = (question) => (question.isConditionallyDisabled ? 'conditionally-disabled' : 'enabled');

  return (
    <div className={`form-container ${validated ? 'validated' : ''}`}>
      {inputData.title && <div className="title">{inputData.title}</div>}

      <Form noValidate validated={validated && !inputData.disabled} className="row" onSubmit={onSave}>

        {inputData.description && <div className="description">{inputData.description}</div>}
        {inputData.messages?.map((msg) => msg && (
          <div className="col-12" key={hash(msg)}>
            <Alert variant={msg.type} className="flex-row">
              <MessageIcon icon={msg.icon} />
              <p className="alert-icon-padding">{msg.text}</p>
            </Alert>
          </div>
        ))}

        {questions.map((question) => (
          <div className={`col-${getColumnSize(question)} ${getConditionalDisabledClass(question)}`} key={hash(question)}>
            {renderQuestion(question)}
          </div>
        ))}

        <div className="buttons">
          {(!inputData.viewMode && !inputData.disabled) && (
            <Button type="submit" disabled={!validated}>
              {inputData.submitText ?? 'Save'}
            </Button>
          )}
          {
            inputData.showDiscard && (
              <Button
                variant="outline-secondary"
                onClick={() => sendMessage('DISCARD', inputData.data)}
                className="ms-2"
              >
                {inputData.discardText ?? 'Discard'}
              </Button>
            )
          }
        </div>
      </Form>
      {
        panel
        && (
          <SidePanel close={() => closeEditPanel()} style={{ width: '60%' }}>
            <SidePanel.Header
              title={panel.title}
              subtitle={panel.subtitle}
              style={{ borderBottom: 0 }}
            />
            <SidePanel.Content>
              {
                panel.form && (
                  <JulotaForm
                    inputData={panel.form}
                    formDirty
                    callbackFunction={(message) => handleEdits(message)}
                  />
                )
              }
            </SidePanel.Content>
          </SidePanel>
        )
      }
    </div>
  );
}
