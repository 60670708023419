import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AdvancedConfiguration from 'modules/clients/pages/AdvancedConfiguration';
import ChangePassword from 'modules/core/components/ChangePassword';
import Client from 'modules/clients/pages/Client';
import Clients from 'modules/clients/pages/Clients';
import ClientUsage from 'modules/usage/pages/ClientUsage';
import Dashboard from 'modules/clients/pages/Dashboard';
import Datasets from 'modules/clients/pages/Datasets';
import Home from 'modules/core/pages/Home';
import Interfaces from 'modules/clients/pages/Interfaces';
import AppLayout from 'modules/core/pages/AppLayout';
import Login from 'modules/core/pages/Login';
import LoginForm from 'modules/core/components/LoginForm';
import Logout from 'modules/core/pages/Logout';
import Modules from 'modules/clients/pages/Modules';
import Packages from 'modules/clients/pages/Packages';
import NotFound from 'modules/core/pages/NotFound';
import Notifications from 'modules/clients/pages/Notifications';
import Profile from 'modules/clients/pages/Profile';
import EntityList from 'modules/clients/pages/EntityList';
import Entity from 'modules/clients/pages/Entity';
import Settings from 'modules/settings/pages/Settings';
import Layouts from 'modules/clients/pages/Layouts';
import LayoutPage from 'modules/clients/pages/LayoutPage';
import Components from 'modules/clients/pages/Components';
import Component from 'modules/clients/pages/Component';
import TwoFactorAuth from 'modules/core/components/TwoFactorAuth';
import Users from 'modules/users/pages/Users';
import Versions from 'modules/clients/pages/Versions';

import 'react-toastify/dist/ReactToastify.min.css';
import { ToastContainer } from 'react-toastify';
import DataExports from 'modules/clients/pages/DataExports';
import Organizations from 'modules/clients/pages/Organizations';
import Groups from 'modules/clients/pages/Groups';
import Teams from 'modules/clients/pages/Teams';
import MedicalInsurers from 'modules/clients/pages/MedicalInsurers';

function App() {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />}>
            <Route path="" element={<LoginForm />} />
            <Route path="verify" element={<TwoFactorAuth />} />
            <Route path="chpass" element={<ChangePassword />} />
          </Route>
          <Route path="/logout" element={<Logout />} />
          <Route path="/" element={<AppLayout />}>
            <Route path="" element={<Home />} />
            <Route path="*" element={<NotFound />} />
            <Route path="client-usage" element={<ClientUsage />} />
            <Route path="hubs" element={<Clients />} />
            <Route path="hubs/:clientId" element={<Client />}>
              <Route path="*" element={<NotFound />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="datasets" element={<Datasets />} />
              <Route path="layouts" element={<Layouts />} />
              <Route path="layouts/:key" element={<LayoutPage />} />
              <Route path="organizations" element={<Organizations />} />
              <Route path="groups" element={<Groups />} />
              <Route path="teams" element={<Teams />} />
              <Route path="medical-insurers" element={<MedicalInsurers />} />
              <Route path="components" element={<Components />} />
              <Route path="components/:key" element={<Component />} />
              <Route path="interfaces" element={<Interfaces />} />
              <Route path="data-exports" element={<DataExports />} />
              <Route path="modules" element={<Modules />} />
              <Route path="packages" element={<Packages />} />
              <Route path="notifications" element={<Notifications />} />
              <Route path="profile" element={<Profile />} />
              <Route path="settings" element={<Settings />} />
              <Route path="advanced-configuration" element={<AdvancedConfiguration />} />
              <Route path="users" element={<Users />} />
              <Route path="users/:userid" element={<Users />} />
              <Route path="versions" element={<Versions />} />

              <Route path="assessments" element={<EntityList entityType="assessment" />} />
              <Route path="assessments/:entityKey/:id" element={<Entity entityType="assessment" />} />
              <Route path="facesheets" element={<EntityList entityType="facesheet" />} />
              <Route path="facesheets/:entityKey/:id" element={<Entity entityType="facesheet" />} />
              <Route path="forms" element={<EntityList entityType="form" />} />
              <Route path="forms/:entityKey/:id" element={<Entity entityType="form" />} />
              <Route path="group-services" element={<EntityList entityType="group-service" />} />
              <Route path="group-services/:entityKey/:id" element={<Entity entityType="group-service" />} />
              <Route path="protocols" element={<EntityList entityType="protocol" />} />
              <Route path="protocols/:entityKey/:id" element={<Entity entityType="protocol" />} />
              <Route path="referrals" element={<EntityList entityType="referral" />} />
              <Route path="referrals/:entityKey/:id" element={<Entity entityType="referral" />} />
              <Route path="services" element={<EntityList entityType="service" />} />
              <Route path="services/:entityKey/:id" element={<Entity entityType="service" />} />
              <Route path="surveys" element={<EntityList entityType="survey" />} />
              <Route path="surveys/:entityKey/:id" element={<Entity entityType="survey" />} />
            </Route>
            <Route path="users" element={<Users />} />
            <Route path="users/:userid" element={<Users />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
