import React from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { v4 as uuidv4 } from 'uuid';

export default function ReachButtonGroup(props) {
  const { question } = props;

  return (
    <ButtonGroup aria-label={question.label} className="btn-group">
      {question.options && question.options.map(
        (option) => <Button key={uuidv4()} variant="primary" size="sm">{option.label}</Button>,
      )}
    </ButtonGroup>
  );
}
