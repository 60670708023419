import React from 'react';
import { Form } from 'react-bootstrap';

export default function JulotaNumberPicker(props) {
  const {
    value,
    question,
    callbackFunction,
  } = props;

  const onChange = (e) => {
    const newValue = parseInt(e.target.value, 10);

    callbackFunction({
      target: {
        name: question.field,
        value: newValue,
      },
    });
  };

  return (
    <Form.Group className="mb-3">
      <Form.Label className="label">
        {question.label}
        {question.required && <span>*</span>}
      </Form.Label>

      <Form.Control
        type="number"
        onChange={onChange}
        value={value}
        required={question.required}
        min={question.min || 0}
        max={question.max || 100}
        placeholder={question.placeholder || question.label}
        disabled={question.disabled}
      />

      <Form.Control.Feedback type="invalid">
        Missing Required Field
      </Form.Control.Feedback>
    </Form.Group>
  );
}
