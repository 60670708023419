import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { selectSessionHasWriteAccess } from 'redux/selectors';
import { useLocation, useParams } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import {
  Key, PencilSquare, Plus, XSquare,
} from 'react-bootstrap-icons';
import { Button, Tab, Tabs } from 'react-bootstrap';
import GenericUtils from 'utils/GenericUtils';
import Subheader from 'components/Subheader';
import JulotaForm from 'components/Forms/JulotaForm';
import SearchBar from 'components/Search/SearchBar';
import { toast } from 'react-toastify';
import ConfirmToast from 'modules/core/components/ConfirmToast';
import SidePanel from 'components/Table/SidePanel';
import useFormSubmit from '../hooks/useFormSubmit';
import UsersService from '../services/UsersService';
import { initialTabs, tabList } from '../const/const';

const columnDefs = [
  { headerName: 'First Name', field: 'first_name', tooltipField: 'first_name' },
  { headerName: 'Last Name', field: 'last_name', tooltipField: 'last_name' },
  { headerName: 'Username', field: 'username', tooltipField: 'username' },
  { headerName: 'Gender', field: 'sex', tooltipField: 'sex' },
  { headerName: 'Status', field: 'status', tooltipField: 'status' },
  {
    headerName: 'Create Date',
    field: 'createdt',
    tooltipValueGetter: (p) => GenericUtils.formatDateShort(p.value),
    cellRenderer: (parameters) => GenericUtils.formatDateShort(parameters.value),
  },
  {
    headerName: 'Last Login',
    field: 'last_login',
    cellRenderer: ({ value }) => (value ? GenericUtils.formatDateShort(value) : ''),
    tooltipValueGetter: (p) => (p.value ? GenericUtils.formatDateShort(p.value) : ''),
  },
];

const nonTogglableViewModeTabs = [3, 4, 5, 6];
const defaultColDef = {
  sortable: true,
  filter: true,
  resizable: true,
};

export default function Users() {
  const { clientId } = useParams();
  const gridRef = useRef();
  const location = useLocation();

  const [search, setSearch] = useState('');
  const [create, setCreate] = useState(false);
  const [tabs, setTabs] = useState(initialTabs);

  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [userAuth, setUserAuth] = useState();

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [formDirty, setFormDirty] = useState(false);
  const [activeTabId, setActiveTabId] = useState(0);
  const [isViewModeTogglable, setIsViewModeTogglable] = useState(true);
  const hasWriteAccess = useSelector(selectSessionHasWriteAccess);

  const fetchUsers = async () => {
    const res = await UsersService.getUsers(clientId || null);
    setUsers(res);
    setGridData(res);
  };

  const { handleCallback, fetchUserDetailDataByTabName, getUserById } = useFormSubmit({
    activeTabId,
    isViewModeTogglable,
    setTabs,
    create,
    clientId: clientId || userAuth?.client_id || null,
    selectedUserData,
    fetchUsers,
    setCreate,
    gridRef,
    setSelectedRow,
    setActiveTabId,
    setFormDirty,
    setLoading,
    setSelectedUserData,
    tabs,
  });

  const activeTabData = useMemo(() => {
    if (tabs && tabs[activeTabId].form) {
      return tabs[activeTabId];
    }

    return null;
  }, [tabs, activeTabId]);

  const subheaderData = useMemo(
    () => [
      `Created on ${GenericUtils.formatDateShort(selectedUserData?.createdt)} by ${selectedUserData?.createdby}`,
      selectedUserData?.updatedt
      && selectedUserData?.updatedby
      && `Update on ${GenericUtils.formatDateShort(selectedUserData?.updatedt)} by ${selectedUserData?.updatedby}`,
      selectedUserData?.last_login && `Last Login: ${GenericUtils.formatDateShort(selectedUserData?.last_login)}`,
    ],
    [selectedUserData],
  );

  const onGridReady = (pars) => {
    pars.api.sizeColumnsToFit();

    window.onresize = () => {
      pars.api.sizeColumnsToFit();
    };
  };

  const onRowClicked = useCallback(() => {
    const onConfirm = async () => {
      const selectedRows = gridRef.current.api.getSelectedRows();
      setCreate(false);
      setSelectedRow(selectedRows[0]);
      setTabs(initialTabs);

      const userData = await getUserById({ userId: selectedRows[0].id });

      fetchUserDetailDataByTabName({
        tabName: tabs[0].title, clientID: userAuth?.client_id || null, userData,
      });
      setFormDirty(false);
      setActiveTabId(0);
    };

    if (formDirty) {
      toast(
        <ConfirmToast
          config={GenericUtils.discardChangesToastConfig}
          confirm={onConfirm}
        />,
        GenericUtils.confirmToastOptions,
      );
    } else {
      onConfirm();
    }
  }, [formDirty]);

  const closeSidePanel = () => {
    const onConfirm = () => {
      setSelectedRow(null);
      setTabs(initialTabs);
      setSelectedUserData(null);
      setCreate(false);
      setFormDirty(false);
    };

    if (formDirty) {
      toast(
        <ConfirmToast
          config={GenericUtils.discardChangesToastConfig}
          confirm={onConfirm}
        />,
        GenericUtils.confirmToastOptions,
      );
    } else {
      onConfirm();
    }
  };

  const onCreateClick = async () => {
    setCreate(true);

    setTabs([
      {
        title: 'User Detail',
        form: {
          id: null,
          questions: await UsersService.getUserCreateFormQuestions(),
          data: {
            active: true,
            date_of_birth: '',
            first_name: '',
            last_name: '',
            mfa_email: '',
            mfa_sms: '',
            notification_email: '',
            notification_sms: '',
            notification_strategy: 'default',
            sex: 'Unknown',
            user_id: '',
            username: '',
            send_email: true,
          },
          viewMode: false,
          submitText: 'Create User',
        },
      },
    ]);
    setLoading(false);
    setSelectedRow(null);
  };

  const toggleViewMode = (tabIndex, viewMode) => {
    const onConfirm = async () => {
      const modifiedTabsState = [...tabs];
      modifiedTabsState[tabIndex].form.viewMode = !modifiedTabsState[tabIndex].form.viewMode;

      setTabs(modifiedTabsState);

      setFormDirty(false);
    };

    if (formDirty) {
      toast(
        <ConfirmToast
          config={GenericUtils.discardChangesToastConfig}
          confirm={onConfirm}
        />,
        GenericUtils.confirmToastOptions,
      );
    } else {
      onConfirm();
    }
  };

  const handleSelectTab = (eventKey) => {
    const onConfirm = async () => {
      const newActiveTab = parseInt(eventKey, 10);

      if (!tabs[newActiveTab].form) {
        fetchUserDetailDataByTabName({
          tabName: tabList[newActiveTab],
          clientID: userAuth.client_id,
        });
      }

      setActiveTabId(newActiveTab);

      setFormDirty(false);
    };

    if (formDirty) {
      toast(
        <ConfirmToast
          config={GenericUtils.discardChangesToastConfig}
          confirm={onConfirm}
        />,
        GenericUtils.confirmToastOptions,
      );
    } else {
      onConfirm();
    }
  };

  const handleEmailResend = () => {
    const onConfirm = async () => {
      try {
        setLoading(true);
        await UsersService.sendResetPasswordEmail(
          clientId,
          selectedUserData.user_id,
          selectedUserData.username,
          selectedUserData.mfa_email,
        );
        toast.success('Reset password email sent.');
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
      }
    };

    toast(
      <ConfirmToast
        config={GenericUtils.resetPasswordToastConfig}
        confirm={onConfirm}
      />,
      GenericUtils.confirmToastOptions,
    );
  };

  const fetchUserAuth = async (userId) => {
    const resp = await UsersService.getUserAuthById(userId);
    setUserAuth(resp);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (nonTogglableViewModeTabs.includes(activeTabId)) {
      setIsViewModeTogglable(false);
    } else {
      setIsViewModeTogglable(true);
    }
  }, [activeTabId]);

  useEffect(() => {
    if (search.length > 0) {
      setGridData(GenericUtils.search(users, search));
    } else {
      setGridData(users);
    }
  }, [search]);

  useEffect(() => {
    if (selectedRow) {
      fetchUserAuth(selectedRow.id);
    }
  }, [selectedRow]);

  return (
    <div className="container-padding-40">
      <div className="row">
        <div className="col-lg-3 col-6">
          <h2>Users</h2>
          <Subheader description={[`${gridData ? gridData.length.toLocaleString() : 0} items`]} />
        </div>
        <div className="col-md-6 search-bar-users">
          <SearchBar
            placeholder="Search Users..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        {location.pathname.includes('hubs')
          && (
            <div className="col-md-3 text-align-right">
              <Button variant="link" size="sm" onClick={onCreateClick}>
                <Plus />
                <span>Add User</span>
              </Button>
            </div>
          )}
      </div>
      <div className="ag-theme-alpine ag-container">
        <AgGridReact
          ref={gridRef}
          rowData={gridData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          tooltipShowDelay={1500}
          rowSelection="single"
          suppressContextMenu="true"
          onRowClicked={onRowClicked}
          onGridReady={onGridReady}
          animateRows
          suppressScrollOnNewData="true"
        />
      </div>
      {
        ((selectedRow && tabs) || create)
        && (
          <SidePanel
            close={closeSidePanel}
            loading={loading}
            confirmClosePrompt={formDirty}
            style={{ width: '60%', minWidth: '700px' }}
          >
            <SidePanel.Header
              title={create ? 'Add User' : `${selectedUserData?.first_name} ${selectedUserData?.last_name}`}
              style={{ borderBottom: 0 }}
            >
              {!create && <Subheader description={subheaderData} />}
            </SidePanel.Header>

            <SidePanel.Content>
              <Tabs
                onSelect={handleSelectTab}
                activeKey={activeTabId}
                defaultActiveKey={0}
                mountOnEnter
                unmountOnExit
              >
                {tabs?.map((tab, index) => (
                  <Tab eventKey={index} title={tab.title} key={tab.title}>
                    {!create && hasWriteAccess && (
                      <div className="float-right mt-2">
                        {index === 0 && (
                          <Button
                            disabled={!tab.form?.viewMode}
                            variant="link"
                            size="sm"
                            onClick={() => handleEmailResend()}
                          >
                            <Key />
                            <span>Reset Password</span>
                          </Button>
                        )}
                        {isViewModeTogglable && (
                          <Button variant="link" size="sm" onClick={() => toggleViewMode(index, tab.form?.viewMode)}>
                            {tab.form?.viewMode ? <PencilSquare /> : <XSquare />}
                            <span>{tab.form?.viewMode ? 'Edit' : 'Cancel'}</span>
                          </Button>
                        )}
                      </div>
                    )}
                  </Tab>
                ))}
              </Tabs>
              {activeTabData && (
                <div className="mt-4">
                  <JulotaForm
                    inputData={activeTabData.form}
                    callbackFunction={(message) => handleCallback(message)}
                    viewMode={activeTabData.form?.viewMode}
                    formDirty={formDirty}
                    key={activeTabData.title}
                  />
                </div>
              )}
            </SidePanel.Content>
          </SidePanel>
        )
      }
    </div>
  );
}
