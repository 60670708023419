/* eslint-disable react/react-in-jsx-scope */
import FetchService from 'services/FetchService';
import GenericUtils from 'utils/GenericUtils';
import DescriptionCellRenderer from 'components/Table/DescriptionCellRenderer';
import Tooltip from 'components/Table/Tooltip';

export default class EntityService {
  static async getPageData(clientId, entityType) {
    return {
      templates: await FetchService.fetchData('GET', `entities/templates?entity_type=${entityType}&client_id=${clientId}`),
      data: await FetchService.fetchData('GET', `entities/data?entity_type=${entityType}&client_id=${clientId}`),
    };
  }

  static async getConfigurationForm(clientId, entityType, id) {
    return {
      id,
      questions: await this.getQuestions(entityType),
      data: await FetchService.fetchData('GET', `entities/${id}?entity_type=${entityType}&client_id=${clientId}`),
    };
  }

  static async getTabs(clientId, entityType, entityKey, id) {
    if (entityType === 'service') {
      const service = await FetchService.fetchData('GET', `services/${entityKey}/${id}`);
      const configTabs = await FetchService.fetchData('POST', 'services/tabs', { service, clientId });

      return configTabs.concat([
        {
          key: 'layout',
          title: 'Layout',
          type: 'LAYOUT_CONFIGURATION',
        },
      ]);
    }

    return [
      {
        key: 'layout',
        title: 'Layout',
        type: 'LAYOUT_CONFIGURATION',
      }, {
        key: 'configuration',
        title: 'Configuration',
        type: 'JULOTA_FORM',
        form: await this.getConfigurationForm(clientId, entityType, id),
      },
    ];
  }

  static async getTemplate(entityType, templateKey) {
    return {
      templateKey,
      questions: await this.getQuestions(entityType),
      data: await FetchService.fetchData('GET', `entities/templates/${templateKey}?entity_type=${entityType}`),
    };
  }

  static async getQuestions(entityType) {
    return FetchService.fetchData('GET', `entities/questions?&entity_type=${entityType}`);
  }

  static createOrUpdate(clientId, entityType, id, body) {
    if (id) {
      return FetchService.fetchData('PUT', `entities/${id}?&entity_type=${entityType}&client_id=${clientId}`, body);
    }

    return FetchService.fetchData('POST', `entities?&entity_type=${entityType}`, body);
  }

  static getEntityColumn(entityType) {
    if (entityType === 'facesheet') {
      return { headerName: 'Facesheet Key', field: 'facesheet_key' };
    }
    if (entityType === 'form') {
      return { headerName: 'Form Key', field: 'form_key' };
    }
    if (entityType === 'service') {
      return { headerName: 'Service Key', field: 'service_key' };
    }
    if (entityType === 'survey') {
      return { headerName: 'Survey Key', field: 'survey_key' };
    }

    return {};
  }

  static getColumnDefs(entityType) {
    if (entityType === 'protocol') {
      return [
        {
          field: 'name',
          headerName: 'Name / Description',
          tooltipValueGetter: (p) => ({
            title: p.data.name,
            subtitle: p.data.description,
          }),
          tooltipComponent: Tooltip,
          cellRenderer: ({ data }) => (
            <DescriptionCellRenderer name={data.name} description={data.description} />
          ),
          // pinned: 'left',
        },
        {
          field: 'key',
          tooltipField: 'key',
          headerName: 'Key',
        },
        {
          field: 'types',
          tooltipField: 'types',
          headerName: 'Types',
        },
        {
          field: 'designations',
          tooltipField: 'designations',
          headerName: 'Designations',
        },
        {
          field: 'enforcement_strategy',
          tooltipField: 'enforcement_strategy',
          headerName: 'Enforcement Strategy',
        },
        {
          field: 'expiry',
          tooltipField: 'expiry',
          headerName: 'Expiry',
        },
        {
          field: 'required',
          tooltipField: 'required',
          cellRenderer: (params) => (params.value ? 'Yes' : 'No'),
          cellStyle: { textAlign: 'center' },
          width: 75,
          headerClass: 'text-center',
        },
        {
          field: 'status',
          tooltipField: 'status',
          cellStyle: (params) => GenericUtils.statusCellStyle(params),
          width: 75,
          headerClass: 'text-center',
        },
        {
          field: 'number_completed',
          tooltipField: 'number_completed',
          headerName: '# Completed',
        },
        {
          headerName: 'Created Date',
          field: 'createdt',
          tooltipValueGetter: (p) => GenericUtils.formatDateShort(p.value),
          cellRenderer: (params) => GenericUtils.formatDateShort(params.value),
        },
      ];
    }

    return [
      {
        field: 'name',
        headerName: 'Name / Description',
        tooltipValueGetter: (p) => ({
          title: p.data.name,
          subtitle: p.data.description,
        }),
        tooltipComponent: Tooltip,
        cellRenderer: ({ data }) => (
          <DescriptionCellRenderer name={data.name} description={data.description} />
        ),
      },
      {
        field: 'key',
        tooltipField: 'key',
        headerName: 'Key',
      },
      this.getEntityColumn(entityType),
      {
        field: 'status',
        tooltipField: 'status',
        cellStyle: (params) => GenericUtils.statusCellStyle(params),
        width: 75,
        headerClass: 'text-center',
      },
      {
        headerName: 'Created Date',
        field: 'createdt',
        tooltipValueGetter: (p) => GenericUtils.formatDateShort(p.value),
        cellRenderer: (params) => GenericUtils.formatDateShort(params.value),
      },
    ];
  }
}
