import React from 'react';

export default function DefaultTagCellRenderer(props) {
  const { data } = props;

  return (
    <div>
      {data.display}
      {data?.is_default ? <span className="badge badge-pill badge-info">Default</span> : null}
    </div>
  );
}
