import React, { useState, useEffect, useRef } from 'react';
import set from 'lodash.set';
import { useSelector } from 'react-redux';
import { selectSessionHasWriteAccess } from 'redux/selectors';
import { Form, Button } from 'react-bootstrap';
import GenericUtils from 'utils/GenericUtils';
import hash from 'object-hash';
import Toggle from './Toggle';
import TextInput from './TextInput';
import TextArea from './TextArea';
import JsonInput from './JsonInput';
import DropDown from './DropDown';
import InputMask from './InputMask';
import AddressLookup from './AddressLookup';
import EditableTable from './EditableTable';
import Terminology from './Terminology';
import CronInput from './CronInput';
import MultiSelect from './MultiSelect';
import RulesObject from './RulesObject';
import DatePicker from './DatePicker';
import NumberPicker from './NumberPicker';
import RangeSlider from './RangeInput';
import './styles/Forms.scss';

export default function JulotaFormNew(props) {
  const {
    id,
    questions,
    data,
    errors,
    isDirty,
    isDisabled,
    submitText,
    onFormChange,
    onFormSubmit,
  } = props;
  const initialFormData = useRef();
  const [formData, setFormData] = useState({});
  const [formQuestions, setFormQuestions] = useState([]);
  const [validated, setValidated] = useState(false);
  const [fieldsWithErrors, setFieldsWithErrors] = useState([]);
  const hasWriteAccess = useSelector(selectSessionHasWriteAccess);

  const validateQuestion = (question, f) => {
    const conditions = question.conditions?.map((c) => {
      switch (c.operator) {
        case 'ARRAY_SIZE_GREATER_THAN':
          return f[c.field]?.length > c.value;

        default: return f[c.field] === c.value;
      }
    }) || [];

    return !conditions.includes(false);
  };

  const renderQuestions = (fData) => {
    const validatedQuestions = questions.map((q) => ({
      ...q,
      valid: validateQuestion(q, fData),
    }));

    setFormQuestions(validatedQuestions);
  };

  const handleCallback = (e) => {
    const { name, value, type } = e.target;

    set(formData, name, value);

    if (type === 'NOT_VALID') {
      setFieldsWithErrors([...fieldsWithErrors, name]);
    } else if (type === 'VALID') {
      setFieldsWithErrors(fieldsWithErrors.filter((field) => field !== name));
    }

    setFormData(formData);
    renderQuestions(formData);

    onFormChange({
      isDirty: !GenericUtils.areObjectsEqual(formData, initialFormData.current),
      data: formData,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;

    if (form.checkValidity() && validated) {
      onFormSubmit({
        id,
        data: formData,
        initialFormData: initialFormData.current,
      });
    }

    initialFormData.current = structuredClone(formData);
    setValidated(true);
  };

  const renderQuestion = (question) => {
    question.disabled = isDisabled || question.disabled;

    const questionProps = {
      question,
      value: formData[question.field],
      formData,
      errors: errors && GenericUtils.getDeepValue(errors, question.field),
      formDirty: isDirty,
      callbackFunction: handleCallback,
    };

    switch (question.type) {
      case 'TOGGLE': return <Toggle {...questionProps} />;
      case 'TEXT': return <TextInput {...questionProps} />;
      case 'TEXTAREA': return <TextArea {...questionProps} />;
      case 'JSONINPUT': return <JsonInput {...questionProps} />;
      case 'DROPDOWN': return <DropDown {...questionProps} />;
      case 'INPUTMASK': return <InputMask {...questionProps} />;
      case 'NUMBERPICKER': return <NumberPicker {...questionProps} />;
      case 'ADDRESSLOOKUP': return <AddressLookup {...questionProps} />;
      case 'EDITABLETABLE': return <EditableTable {...questionProps} />;
      case 'TERMINOLOGY': return <Terminology {...questionProps} />;
      case 'DATEPICKER': return <DatePicker {...questionProps} />;
      case 'CRONINPUT': return <CronInput {...questionProps} />;
      case 'MULTISELECT': return <MultiSelect {...questionProps} />;
      case 'RANGESLIDER': return <RangeSlider {...questionProps} />;
      case 'RULES': return <RulesObject {...questionProps} />;
      default: return <div>UNRECOGNIZED QUESTION TYPE</div>;
    }
  };

  useEffect(() => {
    initialFormData.current = structuredClone(data);
  }, []);

  useEffect(() => {
    setValidated((fieldsWithErrors.length === 0) && isDirty && hasWriteAccess);
    setFormData(data);
    renderQuestions(data);
  }, [fieldsWithErrors, isDirty, data]);

  useEffect(() => {
    renderQuestions(formData);
  }, [questions]);

  return (
    <div className={`form-container ${validated ? 'validated' : ''}`}>
      <Form className="row" noValidate validated={validated && !isDisabled}>
        {formQuestions.map((question) => (
          <div className={`col-12 ${question.valid ? 'enabled' : 'disabled'}`} key={hash(question)}>
            {renderQuestion(question)}
          </div>
        ))}

        <div className="buttons">
          {(!isDisabled) && (
            <Button type="submit" disabled={!validated} onClick={onSubmit}>
              {submitText ?? 'Save'}
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
}
