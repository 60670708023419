import React from 'react';
import './styles/Tooltip.scss';

export default function Tooltip({ value: { title, subtitle } }) {
  return (
    <div className="custom-tooltip">
      <div className="custom-tooltip-container">
        {title && <div className="custom-tooltip-title">{title}</div>}
        {subtitle && <div>{subtitle}</div>}
      </div>
    </div>
  );
}
