import React from 'react';
import {
  Badge,
  Button,
  Col,
  ListGroup,
  Row,
} from 'react-bootstrap';
import { Stopwatch } from 'react-bootstrap-icons';
import GenericUtils from 'utils/GenericUtils';
import { useSelector } from 'react-redux';
import { selectSelectedClientTimezone } from 'redux/selectors';

export default function RunHistoryItem({
  icon,
  title,
  processTime,
  statusReason,
  metadata,
  status,
  createdt,
  updatedt,
  onDetailsClick,
}) {
  const getVariantByStatus = (value) => {
    switch (value) {
      case 'completed':
        return 'success';
      case 'failed':
        return 'danger';
      default:
        return 'warning';
    }
  };
  const selectedClientTimezone = useSelector(selectSelectedClientTimezone);

  const formatSeconds = (time) => {
    if (!time) {
      return '<1s';
    }
    if (!time.seconds || time.seconds === 0) {
      return (time?.hours > 0 || time?.minutes > 0) ? '' : '<1s';
    }

    return `${time.seconds}s `;
  };

  return (
    <ListGroup.Item>
      <Row>
        <Col>
          <div style={{ fontWeight: 600 }}>
            {icon}
            {title}
            <Badge pill bg="light" style={{ color: 'var(--color-1)', marginLeft: 6 }}>
              <Stopwatch style={{ marginRight: 2 }} />
              {processTime?.hours > 0 && `${processTime.hours}hs `}
              {processTime?.minutes > 0 && `${processTime.minutes}m `}
              {formatSeconds(processTime)}
            </Badge>
          </div>

          <div style={{ margin: '8px 0', fontSize: 12 }}>
            <div>
              {`Created Date: ${GenericUtils.formatDateLongAtTimezone(createdt, selectedClientTimezone)}`}
            </div>

            <div>
              {`Process Date: ${GenericUtils.formatDateLongAtTimezone(updatedt, selectedClientTimezone)}`}
            </div>
            {
              statusReason
              && <div>{`Message: ${statusReason}`}</div>
            }
            {
              Object.keys(metadata).length > 0 && (
                <Button
                  variant="link"
                  onClick={onDetailsClick}
                  style={{ padding: 0, fontSize: 12 }}
                >
                  Details
                </Button>
              )
            }
          </div>

        </Col>
        <Col xs={2} className="ms-auto text-end">
          <Badge
            pill
            bg={getVariantByStatus(status)}
            style={{ textTransform: 'capitalize' }}
          >
            {status}
          </Badge>
        </Col>
      </Row>
    </ListGroup.Item>
  );
}
