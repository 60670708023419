/* eslint-disable react/react-in-jsx-scope */
import FetchService from 'services/FetchService';
import hash from 'object-hash';
import TeamService from 'modules/clients/services/TeamService';
import NotificationsService from 'modules/clients/services/NotificationsService';
import GenericUtils from 'utils/GenericUtils';
import Tooltip from 'components/Table/Tooltip';

export default class UsersService {
  static getUsers(clientId) {
    if (clientId) {
      return FetchService.fetchData('GET', `clients/${clientId}/users`);
    }

    return FetchService.fetchData('GET', 'users');
  }

  static generateUniqueUsername(firstName, lastName) {
    return FetchService.fetchData('POST', 'users/generateUsername/', null, {
      first_name: firstName,
      last_name: lastName,
    });
  }

  static isUsernameAvailable(username) {
    if (!username) {
      return false;
    }

    return FetchService.fetchData('GET', 'users/isUsernameAvailable/', null, {
      username,
    });
  }

  static getUserById(id) {
    return FetchService.fetchData('GET', `users/${id}`);
  }

  static getDetailsFormQuestions() {
    return FetchService.fetchData('GET', 'users/details-form-questions');
  }

  static getUserHubsById(userId) {
    return FetchService.fetchData('GET', 'users/hubs', null, { user_id: userId });
  }

  static getHubsFormQuestions() {
    return FetchService.fetchData('GET', 'users/hubs/form-questions');
  }

  static getUserAuthById(userId) {
    return FetchService.fetchData('GET', 'users/auth', null, { user_id: userId });
  }

  static getAuthFormQuestions(type) {
    return FetchService.fetchData('GET', 'users/auth/form-questions', null, { type });
  }

  static async getAllUserRoles(userId) {
    const clientRoles = await FetchService.fetchData('GET', `users/${userId}/roles/all`);

    return clientRoles.map((r) => ({
      label: `${r.description} (${r.name})`,
      value: r.name,
    }));
  }

  static async getRolesByUserId(userId) {
    const data = await FetchService.fetchData('GET', `users/${userId}/roles`);

    return data.map((r) => r.name);
  }

  static async updateUserRoles(userId, roles) {
    await FetchService.fetchData('PATCH', `users/${userId}/roles`, { roles });
  }

  static getUserTeams(userId, clientId) {
    return FetchService.fetchData('GET', `users/${userId}/teams`, null, {
      client_id: clientId,
    });
  }

  static updateUsersTeams(userId, add, remove) {
    return FetchService.fetchData('PUT', `users/${userId}/teams`, { add, remove });
  }

  static sendResetPasswordEmail(clientId, userId, username, email) {
    return FetchService.fetchData('POST', 'users/reset-password', {
      client_id: clientId,
      user_id: userId,
      username,
      email,
    });
  }

  static updateUser(data) {
    return FetchService.fetchData('POST', 'users', data);
  }

  static createUser(data) {
    const user = FetchService.fetchData('POST', `clients/${data.client_id}/users`, {
      username: data.data.username,
      tempPassword: null,
      first_name: data.data.first_name,
      last_name: data.data.last_name,
      date_of_birth: data.data.date_of_birth || null,
      sex: data.data.sex || null,
      avatar: data.data.sex === 'Male' ? 'male-white-light-15' : 'female-white-light-12',
      email_address: data.data.mfa_email,
      sms: data.data.mfa_sms || null,
      send_email: typeof data.data.send_email === 'boolean' ? data.data.send_email : true,
    });

    return user;
  }

  static getUserCreateFormQuestions() {
    return FetchService.fetchData('GET', 'users/create-form-questions');
  }

  static createOrUpdateUser(create, data) {
    if (create) {
      return this.createUser(data);
    }

    return this.updateUser(data);
  }

  static deleteUserById(id) {
    FetchService.fetchData('DELETE', `users/${id}`);
  }

  static getUserOrgs(userId) {
    return FetchService.fetchData('GET', 'users/organizations', null, {
      user_id: userId,
    });
  }

  static async getUserOrgsFormQuestions(clientId) {
    return FetchService.fetchData('GET', 'users/organizations/form-questions', null, {
      client_id: clientId,
    });
  }

  static removeUserOrganizationToastConfig = (username, orgName) => ({
    title: 'Remove from Organization',
    message: `Are you sure you want to remove the user "${username}" from the "${orgName}" Organization?`,
    cancelButton: 'Cancel',
    confirmButton: 'Remove',
  });

  static updateUserOrgs(userId, update, remove) {
    return FetchService.fetchData('PUT', 'users/organizations', { userId, update, remove });
  }

  static async getUserDetailTab(userData, tabs) {
    return {
      title: 'User Detail',
      form: {
        id: userData.user_id,
        questions: await UsersService.getDetailsFormQuestions(),
        data: UsersService.getDetailsFormData(userData),
        viewMode:
          tabs && typeof tabs[0]?.form?.viewMode === 'boolean' ? tabs[0].form.viewMode : true,
      },
    };
  }

  static async getAccessRolesTab(userData, tabs) {
    const allUserRoles = await UsersService.getAllUserRoles(userData.user_id);

    return {
      title: 'Access Roles',
      form: {
        id: userData.user_id,
        questions: [
          {
            label: 'Roles',
            field: 'roles',
            type: 'MULTISELECT',
            options: allUserRoles,
            formatter: (value) => {
              const displayRoles = allUserRoles.filter((r) => (value.includes(r.value)));

              return (
                <ul>
                  {typeof value !== 'string' && displayRoles.map((item) => <li key={hash(item.value)}>{item.label}</li>)}
                </ul>
              );
            },
            customStyles: {
              valueContainer: (base) => ({
                ...base,
                maxHeight: '15vh',
                overflowY: 'auto',
              }),
              menu: (base) => ({
                ...base,
                zIndex: 100,
              }),
              menuList: (base) => ({
                ...base,
                maxHeight: '20vh',
                overflowY: 'auto',
              }),
            },
          },
        ],
        data: {
          roles: await UsersService.getRolesByUserId(userData.user_id),
        },
        viewMode:
          tabs && typeof tabs[1]?.form?.viewMode === 'boolean' ? tabs[1].form.viewMode : true,
      },
    };
  }

  static async getAuthenticationTab(userData, authData, tabs) {
    return {
      title: 'Authentication',
      form: {
        id: userData.user_id,
        questions: await UsersService.getAuthFormQuestions(authData.type),
        data: UsersService.getAuthFormData(authData, userData),
        viewMode:
          tabs && typeof tabs[2]?.form?.viewMode === 'boolean' ? tabs[2].form.viewMode : true,
      },
    };
  }

  static async getTeamsTab(userData, clientId, teamsData) {
    const questions = await TeamService.getTeamsFormQuestions(clientId);
    const formatted = questions.map((q) => ({
      ...q,
      columnDefs: q.columnDefs.map((col) => {
        if (col.field === 'status') {
          return {
            ...col,
            cellRenderer: ({ value }) => GenericUtils.capitalizeFirstLetter(value),
            cellStyle: (params) => GenericUtils.statusCellColor(params.value === 'active'),
            tooltipComponent: Tooltip,
            tooltipValueGetter: ({ value }) => ({
              subtitle: GenericUtils.capitalizeFirstLetter(value),
            }),
          };
        }

        return col;
      }),
    }));

    return {
      title: 'Teams',
      form: {
        id: userData.user_id,
        questions: formatted,
        data: { teams: teamsData },
        viewMode: false,
      },
    };
  }

  static async getNotificationsTab(userData, clientId) {
    return {
      title: 'Notifications',
      form: {
        id: userData.user_id,
        questions: await NotificationsService.getNotificationsFormQuestions(),
        // eslint-disable-next-line max-len
        data: {
          notifications: await NotificationsService.getUserNotification(
            clientId,
            userData.user_id,
          ),
        },
        viewMode: false,
      },
    };
  }

  static async getOrganizationsTab(userData, clientId, orgsData) {
    const questions = await UsersService.getUserOrgsFormQuestions(clientId);
    const formatted = questions.map((q) => ({
      ...q,
      columnDefs: q.columnDefs.map((col) => {
        if (col.field === 'active') {
          return {
            ...col,
            cellRenderer: (params) => UsersService.getStatusFromValue(params.value),
            cellStyle: (params) => GenericUtils.statusCellColor(params.value === 'true'),
            tooltipComponent: Tooltip,
            tooltipValueGetter: (p) => ({
              subtitle: UsersService.getStatusFromValue(p.data.active),
            }),
          };
        }

        return col;
      }),
      rowActions: [
        {
          name: () => 'Mark as Primary',
          action: (params) => {
            if (params.node.data.relation !== 'primary') {
              params.api.forEachNode((
                rowNode,
              ) => {
                const currentItem = rowNode.data;
                const clickedItem = params.node.data;
                currentItem.relation = currentItem.id === clickedItem.id ? 'primary' : 'secondary';
              });
            }
          },
        },
      ],
    }));

    return {
      title: 'Organizations',
      form: {
        id: userData.user_id,
        questions: formatted,
        data: { organizations: orgsData },
        viewMode: false,
      },
    };
  }

  static async getHubsTab(userData, hubsData) {
    const questions = await UsersService.getHubsFormQuestions();
    const formatted = questions.map((q) => ({
      ...q,
      columnDefs: q.columnDefs.map((col) => {
        if (col.field === 'active') {
          return {
            ...col,
            cellRenderer: (params) => UsersService.getStatusFromValue(params.value),
            cellStyle: (params) => GenericUtils.statusCellColor(params.value === 'true'),
            tooltipComponent: Tooltip,
            tooltipValueGetter: (p) => ({
              subtitle: UsersService.getStatusFromValue(p.data.active),
            }),
          };
        }

        return col;
      }),
    }));

    return {
      title: 'Hubs',
      form: {
        id: userData.user_id,
        questions: formatted,
        data: { hubs: hubsData },
        viewMode: false,
      },
    };
  }

  static async getTabDataByTabName(userData, tabName, clientId, tabs) {
    if (tabName === 'User Detail') {
      return UsersService.getUserDetailTab(userData, tabs);
    }
    if (tabName === 'Access Roles') {
      return UsersService.getAccessRolesTab(userData, tabs);
    }
    if (tabName === 'Authentication') {
      const authData = await UsersService.getUserAuthById(userData.user_id);

      return UsersService.getAuthenticationTab(userData, authData, tabs);
    }
    if (tabName === 'Teams') {
      const teamsData = await UsersService.getUserTeams(userData.user_id, clientId);

      return UsersService.getTeamsTab(userData, clientId, teamsData);
    }
    if (tabName === 'Notifications') {
      return UsersService.getNotificationsTab(userData, clientId);
    }
    if (tabName === 'Organizations') {
      const orgsData = await UsersService.getUserOrgs(userData.user_id);

      return UsersService.getOrganizationsTab(userData, clientId, orgsData);
    }
    if (tabName === 'Hubs') {
      const hubsData = await UsersService.getUserHubsById(userData.user_id);

      return UsersService.getHubsTab(userData, hubsData);
    }

    return null;
  }

  static getDetailsFormData(user) {
    return {
      id: user?.user_id ?? '',
      ...user,
    };
  }

  static getAuthFormData(auth, user) {
    return {
      ...auth,
      mfa_email: user.mfa_email,
      mfa_sms: user.mfa_sms,
    };
  }

  static getStatusFromValue(value) {
    return value === 'true' ? 'Active' : 'Inactive';
  }
}
